import React from "react";
import { Link } from "react-router-dom";

const Proposition = (props) => {
    return (
        <div className="pb-2">
            <div className="event-card-container w-100">
                <div className="proposition-header pb-2 flex">
                    <div className="w-100">
                        <div className="event-card-title">{props.proposition.__str__}</div>
                        {props.proposition.autor &&
                        <div className="event-card-content">{"Proponente: "}
                        <Link to={"/profile/" + props.proposition.autor} className="link">
                            {props.proposition.nome_autor}
                        </Link>
                        </div>}
                        <div className="event-card-content">Número: {props.proposition.numero_proposicao}</div>
                        <div className="event-card-content">Ano: {props.proposition.ano}</div>
                        <a className="event-card-content link" href={props.proposition.texto_original} target="_blank">Texto original</a>
                    </div>
                    <div className="options px-2 flex align-items-center">
                        <input className="form-btn m-0" onClick={() => props.follow(props.user_id, props.proposition_id, props.follow_bool)} type="submit" value={props.follow_value}/>
                    </div>
                </div>
                <div className="event-card-content">{props.proposition.descricao}</div>
            </div>
        </div>
    )
}

export default Proposition;