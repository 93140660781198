import React, { useEffect, useState } from "react";

import SearchPage from "../components/SeachPage.js";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import fetchContent from "../gets/Fetch.js";
import { api_base_url } from "../App.js";
import Order from "../components/Order.js";
import UpperMenu from "../components/UpperMenu.js";
import Form from "../components/Form.js";
import dbQuery from "../gets/DBQuery.js";

const updateResults = (setResults, newOrders) =>{
    var orders = newOrders;
    var results = [];
    for (var i = 0; i < orders.length; i++) {
        results.push(<Order className={"default-border-top"} order={orders[i]}/>);
    }
    if(orders.length === 0){
        results.push(
            <div className="pb-2">
                <div className="event-card-container w-100">
                    Não houveram resultados para a sua pesquisa.
                </div>
            </div>
        );
    }
    else{
        results.push(
            <div className="pb-2">
                <div className="event-card-container w-100">
                    Fim dos resultados.
                </div>
            </div>
        );
    }
    setResults(results);
}

const filterResults = (orders, query) =>{
    var newOrders = [];
    for (var i = 0; i < orders.length; i++) {
        var order = orders[i];
        var keys = Object.keys(order);
        for (var j = 0; j < keys.length; j++) {
            var key = keys[j];
            try{
                if(order[key].toLowerCase().includes(query.toLowerCase())){
                    newOrders.push(orders[i]);
                    break;
                }
            }
            catch(e){}
        }
    }
    return newOrders;
}

const OrderSearch = (props) => {

    const navigate = useNavigate();

    const search = (e) =>{
        e.preventDefault();
        var { search } = document.getElementsByClassName("search-page-form")[0];
        navigate("/orders/"+encodeURIComponent(search.value));
    }

    const state = { ...localStorage };

    const {query} = useParams();

    const [results, setResults] = useState([]);
    const [order_follower, setOrderFollower] = useState(false);
    const [order_follower_text, setOrderFollowerText] = useState("Seguir ordens do dia");

    var callback = (data, filter) =>{
        var orders = data.results;
        if(filter){
            orders = filterResults(orders, query);
        }
        updateResults(setResults, orders);
    }


    useEffect(()=>{
        var filter = true;

        if(query === undefined){
            filter = false;
        }

        let uri = api_base_url+'/api/sessao/ordemdia/';

        fetchContent(uri, null, 'GET', (data)=>{
            callback(data, filter);
        });

        //get follow value
        dbQuery("get_follow_orders_by_user_id", {}, (data)=>{
            var order_follower = false;
            if(data.length > 0){
                order_follower = data[0].follow === "1" ? true : false;
                setOrderFollower(order_follower);
                setOrderFollowerText(order_follower ? "Seguindo" : "Seguir");
            }
        });
    }, [query]);

    var inputs = {
        1:{
            placeholder:"Buscar ordem do dia", type:"text", name:"search"
        },
    };

    var loggedIn = JSON.parse(localStorage.getItem("isLoggedIn"));

    if(!loggedIn){
        return <Navigate to="/login"/>;
    }

    return (
        <div>
            <UpperMenu/>
            <SearchPage after_content={
            <form className="follow_daily_news" onSubmit={(e) => {
                e.preventDefault();
                //follow(state.user_id, !order_follower);
                var time_followed = new Date().toISOString();
                dbQuery("follow_orders", {follow: !order_follower, time_followed: time_followed}, (data)=>{
                    if(!order_follower){
                        alert("Ordens do dia seguidas com sucesso.");
                    }
                    else{
                        alert("Ordens do dia deixadas de seguir com sucesso.");
                    }
                    //reload page
                    window.location.reload();
                });

            }}>
                <input class="form-btn m-0" type="submit" value={order_follower_text}/>
            </form>
            } form={<Form className={"search-page-form"} formFunction={search} inputs={inputs}/>} title={"Ordens do dia"} results={results} handleSearchSubmit={search}/>
        </div>
    )
};

export default OrderSearch;