import React, { useEffect, useState } from "react";

import { Navigate, useNavigate, useParams } from "react-router-dom";
import fetchContent from "../gets/Fetch.js";
import { api_base_url, db_base_url } from "../App.js";
import Proposition from "../components/Proposition.js";
import Form from "../components/Form.js";
import PropositionForm from "../components/Forms/PropositionForm.js";
import SearchPage from "../components/SeachPage.js";
import UpperMenu from "../components/UpperMenu.js";
import dbQuery from "../gets/DBQuery.js";

const follow = (user_id, proposition_id, follow_bool) =>{
    dbQuery("follow_proposition", {
        proposition_id: proposition_id,
        follow: follow_bool,
        time_followed: new Date().toISOString()
    }, (data)=>{
        if(follow_bool){
            alert("Proposição seguida com sucesso.");
        }
        else{
            alert("Proposição deixada de seguir com sucesso.");
        }
        //reload page
        window.location.reload();
    });
};

const updateResults = (setResults, newPropositions, followed_propositions) =>{
    var user_id = JSON.parse(localStorage.getItem("user_id"));

    var propositions = newPropositions;
    var results = [];
    for (var i = 0; i < propositions.length; i++) {
        var proposition_id = propositions[i].numero_proposicao;

        var follow_bool = false;

        for (var j = 0; j < followed_propositions.length; j++) {
            if(proposition_id === Number(followed_propositions[j].proposition_id)){
                follow_bool = followed_propositions[j].follow === "1";
                break;
            }
        }

        var follow_value = follow_bool ? "Seguindo" : "Seguir";

        results.push(<Proposition className={"default-border-top"} proposition={propositions[i]} user_id={user_id} proposition_id={proposition_id} follow_bool={follow_bool} follow={
            (user_id, proposition_id, follow_bool)=>{follow(user_id, proposition_id, !follow_bool)}
        } follow_value={follow_value}/>);
    }
    if(propositions.length === 0){
        results.push(
            <div className="pb-2">
                <div className="event-card-container w-100">
                    Não houveram resultados para a sua pesquisa.
                </div>
            </div>
        );
    }
    else{
        results.push(
            <div className="pb-2">
                <div className="event-card-container w-100">
                    Fim dos resultados.
                </div>
            </div>
        );
    }
    setResults(results);
}

const filterResults = (propositions, query, number, year) =>{
    number = number !== undefined ? Number(number) : undefined;
    year = year !== undefined ? Number(year) : undefined;

    var filter_count = 0;
    var max_filter_count = 0;
    var query_correspondence = false;

    if(number !== undefined){
        max_filter_count++;
    }
    if(year !== undefined){
        max_filter_count++;
    }

    var newPropositions = [];
    for (var i = 0; i < propositions.length; i++) {

        filter_count = 0;
        query_correspondence = false;

        if(query === undefined){
            query_correspondence = true;
        }

        var proposition = propositions[i];
        var keys = Object.keys(proposition);

        if(number !== undefined){
            if(proposition.numero_proposicao === number){
                filter_count++;
            }
        }
        if(year !== undefined){
            if(proposition.ano === year){
                filter_count++;
            }
        }
        for (var j = 0; j < keys.length; j++) {
            var key = keys[j];
            try{
                if(proposition[key].toLowerCase().includes(query.toLowerCase())){
                    query_correspondence = true;
                    break;
                }
            }
            catch(e){}
            try{
                if(proposition[key] !== undefined && proposition[key] !== null){
                    if(Number(proposition[key]) === Number(query)){
                        query_correspondence = true;
                        break;
                    }
                }
            }
            catch(e){}
        }

        if(query_correspondence && filter_count === max_filter_count){
            newPropositions.push(propositions[i]);
        }
    }
    return newPropositions;
}

const filterByProponent = (propositions, proponent) =>{
    var newPropositions = [];
    for (var i = 0; i < propositions.length; i++) {
        if(propositions[i].nome_autor.toLowerCase().includes(proponent.toLowerCase())){
            newPropositions.push(propositions[i]);
        }
    }
    return newPropositions;
}

const PropositionSearch = (props) => {

    const navigate = useNavigate();

    const search = (e) =>{
        e.preventDefault();
        var { search, proponent, number, year } = document.getElementsByClassName("search-page-form")[0];
        var query = '';
        if(search.value !== ''){
            query += 'search='+encodeURIComponent(search.value);
        }
        if(proponent.value !== ''){
            if(query !== ''){
                query += '&';
            }
            query += 'proponent='+encodeURIComponent(proponent.value);
        }
        if(number.value !== ''){
            if(query !== ''){
                query += '&';
            }
            query += 'number='+encodeURIComponent(number.value);
        }
        if(year.value !== ''){
            if(query !== ''){
                query += '&';
            }
            query += 'year='+encodeURIComponent(year.value);
        }
        navigate("/proposition_search/"+query);
    }

    const {query} = useParams();

    const search_query = query && query.includes("search=") ? query.split("search=")[1].split("&")[0] : undefined;
    const proponent = query && query.includes("proponent=") ? query.split("proponent=")[1].split("&")[0] : undefined;
    const number = query && query.includes("number=") ? query.split("number=")[1].split("&")[0] : undefined;
    const year = query && query.includes("year=") ? query.split("year=")[1].split("&")[0] : undefined;

    const [results, setResults] = useState([]);

    var callback = (data, filter = true) =>{

        var autors = {};

        for (var i = 0; i < data.results.length; i++) {
            autors[i] = data.results[i].autor;
        }

        //get all the propositions that are followed by the user, so the button displays the correct text

        let user_id = JSON.parse(localStorage.getItem("user_id"));

        dbQuery("get_propositions_followed_by_user_id", {}, (followed_propositions)=>{
            var propositions = data.results;

            if(filter){
                propositions = filterResults(propositions, search_query, number, year);
            }
            updateResults(setResults, propositions, followed_propositions);

            //get the names of the propositions autors

            dbQuery("get_users_by_councilor_ids", {
                councilor_ids: Object.values(autors)
            }, (councilors)=>{
                var missing_councilors = {};
                for (var i = 0; i < data.results.length; i++) {
                    var found_author = false;
                    for (var j = 0; j < councilors.length; j++) {
                        if(data.results[i].autor == councilors[j].id_ex_parlamentary){
                            data.results[i].nome_autor = councilors[j].username;
                            found_author = true;
                            break;
                        }
                    }
                    if(!found_author){
                        missing_councilors[i] = data.results[i].autor;
                        propositions[i].nome_autor = undefined;
                    }
                }

                if(filter){
                    propositions = filterResults(propositions, search_query, number, year);
                }
                updateResults(setResults, propositions, followed_propositions);

                //get the names of the propositions autors that are missing

                let uri = api_base_url+'/api/parlamentares/parlamentar/search_parlamentares/';

                fetchContent(uri, null, 'GET', (data)=>{
                    var councilors = data;
                    for (var i = 0; i < Object.keys(missing_councilors).length; i++) {
                        var index = Object.keys(missing_councilors)[i];
                        for (var j = 0; j < councilors.length; j++) {
                            if(councilors[j].id === missing_councilors[index]){
                                propositions[index].nome_autor = councilors[j].nome_parlamentar;
                                break;
                            }
                        }
                    }
                    updateResults(setResults, propositions, followed_propositions);
                    if(filter){
                        propositions = filterByProponent(propositions, proponent);
                        updateResults(setResults, propositions, followed_propositions);
                    }
                });
            });
        });
    }

    useEffect(()=>{
        var filter = true;

        if((search_query === undefined && number === '' && year === '') || query === undefined){
            filter = false;
        }

        let uri = api_base_url+'/api/materia/proposicao/';
        fetchContent(uri, null, 'GET', (data)=>{
            callback(data, filter);
        });
    }, [query]);

    var inputs = PropositionForm;

    var loggedIn = JSON.parse(localStorage.getItem("isLoggedIn"));

    if(!loggedIn){
        return <Navigate to="/login"/>;
    }

    return (
        <div>
            <UpperMenu/>
            <SearchPage form={<Form className={"search-page-form"} formFunction={search} inputs={inputs}/>} title={"Proposições"} results={results}/>
        </div>
    )
};

export default PropositionSearch;