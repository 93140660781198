import React, { useEffect, useState } from "react";

const Order = (props) => {
    return (
        <div className="pb-2">
            <div className="event-card-container w-100">
                <div className="event-card-title">{props.order.__str__}</div>
                <div className="event-card-content">{props.order.observacao}</div>
            </div>
        </div>
    )
}

export default Order;