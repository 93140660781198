import dbQuery from "../gets/DBQuery.js";
import { api_base_url } from "../App";
import PushNotification from "../components/PushNotification.js";
import fetchContent from "../gets/Fetch.js";
import Brasao from "../images/brasao_rg.png";

const GetNotifications = () => {

    var user_id = undefined;
    var get_notifications = true;

    try{
        user_id = JSON.parse(localStorage.getItem("user_id"));
    }catch(e){
        get_notifications = false;
    }

    if(window.location.href === "/notifications"){
        get_notifications = false;
    }

    var unread_propositions = [];
    var unread_councilors = [];
    var unread_orders = [];
    var last_access = undefined;

    const setUnreadPropositions = (new_unread_propositions) => {
        unread_propositions = new_unread_propositions;
    }
    const setUnreadCouncilors = (new_unread_councilors) => {
        unread_councilors = new_unread_councilors;
    }
    const setUnreadOrders = (new_unread_orders) => {
        unread_orders = new_unread_orders;
    }
    const setLastAccess = (new_last_access) => {
        last_access = new_last_access;
    }

    //to check if all the data is loaded
    var unread_propositions_loaded = false;
    var unread_councilors_loaded = false;
    var unread_orders_loaded = false;
    var last_access_loaded = false;

    const setUnreadPropositionsLoaded = (value) => {
        unread_propositions_loaded = value;
    }
    const setUnreadCouncilorsLoaded = (value) => {
        unread_councilors_loaded = value;
    }
    const setUnreadOrdersLoaded = (value) => {
        unread_orders_loaded = value;
    }
    const setLastAccessLoaded = (value) => {
        last_access_loaded = value;
    }

    const filterByFirstOccurrence = (array, key) => {
        var result = [];
        var map = new Map();
        for (const item of array) {
            if(!map.has(item[key])){
                map.set(item[key], true);
                result.push(item);
            }
        }
        return result;
    }

    const filterByKeyValues = (array, key, values) => {
        var result = [];
        for (var i = 0; i < array.length; i++) {
            if(values.includes(array[i][key]) || values.includes(array[i][key].toString())){
                result.push(array[i]);
            }
        }
        return result;
    }

    const listOfKey = (array, key) => {
        var result = [];
        for (var i = 0; i < array.length; i++) {
            result.push(array[i][key]);
        }
        return result;
    }

    const filterByConditionIfKeyMatches = (array1, array2, key1, key2, condition) => {
        //sort array1 by key1
        array1.sort((a, b)=>{return a[key1] - b[key1]});
        //sort array2 by key2
        array2.sort((a, b)=>{return a[key2] - b[key2]});

        //filter array1 by condition
        var result = [];
        var i = 0;
        var j = 0;
        while(i < array1.length && j < array2.length){
            if(array1[i][key1] == array2[j][key2]){
                if(condition(array1[i], array2[j])){
                    result.push(array1[i]);
                }
                i++;
                j++;
            }
            else if(array1[i][key1] < array2[j][key2]){
                i++;
            }
            else{
                j++;
            }
        }

        return result;
    }

    const callback = () => {
        if(get_notifications){
            if(unread_propositions_loaded && unread_councilors_loaded && unread_orders_loaded && last_access_loaded){
                var new_notifications = unread_propositions.concat(unread_councilors).concat(unread_orders);
                
                var new_notification_found = false;
                if(new_notifications.length > 0){
                    if(new Date(new_notifications[0].last_modified) > last_access){
                        new_notification_found = true;
                    }
                }
            }
            if(new_notification_found){
                PushNotification("Nova notificação", {body: "Você tem novas notificações.", icon: Brasao});
            }
        }
    }

    if(get_notifications){
        dbQuery("get_last_access_by_user_id", {}, (data)=>{
            if(data.length > 0){
                let last_access = data[0].last_access;
                last_access = new Date(last_access);
                setLastAccess(last_access);
                setLastAccessLoaded(true);
                dbQuery("set_last_access_by_user_id", {
                    last_access: new Date().toISOString(),
                }, (data)=>{
                    callback();
                });
            }
        });
        dbQuery("get_propositions_followed_by_user_id", {}, (data)=>{
            var propositions_info = data;

            //filter propositions_info to have only the first occurrence of each proposition_id

            propositions_info = filterByFirstOccurrence(propositions_info, "proposition_id");

            //filter propositions_info to have only the propositions followed by the user

            propositions_info = filterByKeyValues(propositions_info, "follow", ["1"]);

            //make a list of propositions ids followed by the user

            var propositions_ids = listOfKey(propositions_info, "proposition_id");

            let followed_propositions = [];
            let counter = 0;
            for(let i = 0; i < propositions_ids.length; i++){
                let url = api_base_url+"/api/materia/proposicao/?numero_proposicao="+propositions_ids[i];
                fetchContent(url, null, "GET", (data)=>{
                    data = data.results;
                    followed_propositions = followed_propositions.concat(data);
                    counter++;

                    if(counter === propositions_ids.length){
                        //filter the propositions that were modified after the user followed them

                        let unread_propositions = filterByConditionIfKeyMatches(followed_propositions, propositions_info, "numero_proposicao", "proposition_id", (a, b)=>{
                            let time_followed = new Date(b.time_followed);
                            let ultima_edicao = new Date(a.ultima_edicao);
                            return time_followed < ultima_edicao;
                        });

                        for(let i = 0; i < unread_propositions.length; i++){
                            unread_propositions[i].name = "Atualização de proposição";
                            unread_propositions[i].description = unread_propositions[i].descricao;
                            unread_propositions[i].last_modified = unread_propositions[i].ultima_edicao;
                            //unread_propositions[i].link = "/proposition/"+unread_propositions[i].numero_proposicao;
                        }

                        setUnreadPropositions(unread_propositions);

                        setUnreadPropositionsLoaded(true);
                        callback();
                    }
                });
            }
            if(propositions_ids.length === 0){
                setUnreadPropositionsLoaded(true);
                callback();
            }
        });
        dbQuery("get_follow_councilor_by_user_id", {}, (data)=>{
            var councilors_info = data;

            //filter councilors_info to have only the first occurrence of each councilor_id

            councilors_info = filterByFirstOccurrence(councilors_info, "councilor_id");

            //filter councilors_info to have only the councilors followed by the user

            councilors_info = filterByKeyValues(councilors_info, "follow", ["1"]);

            //make a list of councilors ids followed by the user
            
            var councilors_ids = listOfKey(councilors_info, "councilor_id");

            let modified_followed_councilors_propositions = [];
            let counter = 0;

            for(let i = 0; i < councilors_ids.length; i++){
                //get all the propositions of the councilor that have been updated after the user followed the councilor
                let url = api_base_url+"/api/materia/proposicao/?autor="+councilors_ids[i];

                fetchContent(url, null, "GET", (data)=>{
                    data = data.results;
                    modified_followed_councilors_propositions = modified_followed_councilors_propositions.concat(data);
                    counter++;

                    if(counter === councilors_ids.length){
                        //filter the propositions that were modified after the user followed them

                        let unread_councilors = filterByConditionIfKeyMatches(modified_followed_councilors_propositions, councilors_info, "autor", "councilor_id", (a, b)=>{
                            let time_followed = new Date(b.time_followed);
                            let ultima_edicao = new Date(a.ultima_edicao);
                            return time_followed < ultima_edicao;
                        });

                        for(let i = 0; i < unread_councilors.length; i++){
                            unread_councilors[i].name = "Atualização de proposição";
                            unread_councilors[i].description = unread_councilors[i].descricao;
                            unread_councilors[i].last_modified = unread_councilors[i].ultima_edicao;
                            //unread_councilors[i].link = "/proposition/"+unread_councilors[i].numero_proposicao;
                        }

                        setUnreadCouncilors(unread_councilors);

                        setUnreadCouncilorsLoaded(true);
                        callback();
                    }
                });
            }
            if(councilors_ids.length === 0){
                setUnreadCouncilorsLoaded(true);
                callback();
            }
        });
        dbQuery("get_follow_orders_by_user_id", {}, (data)=>{
            var orders_info = data;

            //filter orders_info to have only the first occurrence of each order_id

            orders_info = filterByFirstOccurrence(orders_info, "order_id");

            //filter orders_info to have only the orders followed by the user

            orders_info = filterByKeyValues(orders_info, "follow", ["1"]);

            //make a list of orders ids followed by the user

            var orders_ids = listOfKey(orders_info, "order_id");

            //get all orders
            let uri = api_base_url+'/api/sessao/ordemdia/';
            fetchContent(uri, null, 'GET', (data)=>{
                data = data.results;
                //filter the orders that are followed by the user

                var followed_orders = filterByKeyValues(data, "id", orders_ids);

                //filter the orders that were modified after the user followed them

                var unread_orders = filterByConditionIfKeyMatches(followed_orders, orders_info, "id", "order_id", (a, b)=>{
                    var time_followed = new Date(b.time_followed);
                    var ultima_edicao = new Date(a.ultima_edicao);
                    return time_followed < ultima_edicao;
                });

                for(let i = 0; i < unread_orders.length; i++){
                    unread_orders[i].name = "Atualização de ordem do dia";
                    unread_orders[i].description = unread_orders[i].descricao;
                    unread_orders[i].last_modified = unread_orders[i].ultima_edicao;
                    //unread_orders[i].link = "/order/"+unread_orders[i].id;
                }

                setUnreadOrders(unread_orders);

                setUnreadOrdersLoaded(true);
                callback();
            });
        });
    }
}

export default GetNotifications;