import React from "react";
import ImgIcon from '../images/image.png';

const Input = (props) => {
    var classes = props.structure.className? props.structure.className : (props.structure.type !== "checkbox" && "input-container");
    var btn = props.structure.btn? "form-btn" : '';
    var required = props.structure.required? props.structure.required : false

    if(btn){
        classes = props.structure.className? props.structure.className : "w-100 d-flex justify-content-center pb-2";
    }

    var submitValue = props.structure.value? props.structure.value : props.submitButton;

    submitValue = props.structure.type === "submit"? submitValue : undefined;
    
    return (
        <div>
            <div className={classes}>
                {props.structure.label && props.structure.type !== "checkbox" && <label className="gray-text">{props.structure.label}</label>}
                {props.structure.type === "textarea"?
                <textarea className={btn} value={props.structure.value} placeholder={props.structure.placeholder} name={props.structure.name} required={required}/> :
                <input className={btn} value={submitValue} placeholder={props.structure.placeholder} name={props.structure.name} required={required} type={props.structure.type}/>}
                {props.structure.label && props.structure.type === "checkbox" && <label className="gray-text">{props.structure.label}</label>}
            </div>
        </div>
    )
}

const Form = (props) => {

    const inputs = props.inputs;

    var keys = Object.keys(inputs);

    var els = [];

    for(var i = 0; i < keys.length; i++){
        els.push(<Input structure={inputs[keys[i]]} submitButton={props.submitButton}/>);
    }

    var withImgAndSubmit = props.withImgAndSubmit &&
    <div className="post-options d-flex">
        <div className="post-media p-4 d-flex align-items-center">
            <img className="icon" src={ImgIcon} alt="Imagem"/>
        </div>
        <div className="w-100"></div>
        <div className="post-action-button d-flex align-items-center py-4 px-3">
            <input type="submit" className="small-form-btn" value={props.submitButton}/>
        </div>
    </div>;

    return (
        <form className={props.className} onSubmit={props.formFunction}>
            {els}
            {withImgAndSubmit}
        </form>
    )
}

export default Form;