import React from "react";

const Notification = (props) => {
    var read = props.read;
    var opacity = "";
    if(read){
        opacity = "opacity-0-6";
    }
    return (
        <div className="pb-4">
            <div className={"event-card-container w-100 "+opacity}>
                <div className="event-card-title">{props.name}</div>
                <div className="event-card-content">{props.description}</div>
            </div>
        </div>
    )
}

export default Notification;