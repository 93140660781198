import React, { useEffect, useState } from "react";

import SearchPage from "../components/SeachPage.js";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import fetchContent from "../gets/Fetch.js";
import { api_base_url } from "../App.js";
import Session from "../components/Session.js";
import UpperMenu from "../components/UpperMenu.js";
import Form from "../components/Form.js";

const updateResults = (setResults, newResults) =>{
    var results = [];
    for (var i = 0; i < newResults.length; i++) {
        results.push(<Session className={"default-border-top"} session={newResults[i]}/>);
    }
    if(newResults.length === 0){
        results.push(
            <div className="pb-2">
                <div className="event-card-container w-100">
                    Não houveram resultados para a sua pesquisa.
                </div>
            </div>
        );
    }
    else{
        results.push(
            <div className="pb-2">
                <div className="event-card-container w-100">
                    Fim dos resultados.
                </div>
            </div>
        );
    }
    setResults(results);
}

const filterResults = (results, query) =>{
    var newResults = [];
    for (var i = 0; i < results.length; i++) {
        var result = results[i];
        var keys = Object.keys(result);
        for (var j = 0; j < keys.length; j++) {
            var key = keys[j];
            try{
                if(result[key].toLowerCase().includes(query.toLowerCase())){
                    newResults.push(results[i]);
                    break;
                }
            }
            catch(e){}
            try{
                if(result[key] !== undefined && result[key] !== null){
                    if(Number(result[key]) === Number(query)){
                        newResults.push(results[i]);
                        break;
                    }
                }
            }
            catch(e){}
        }
    }
    return newResults;
}

const SessionSearch = (props) => {

    const navigate = useNavigate();

    const search = (e) =>{
        e.preventDefault();
        var { search } = document.getElementsByClassName("search-page-form")[0];
        navigate("/sessions/"+encodeURIComponent(search.value));
    }

    const state = { ...localStorage };

    const {query} = useParams();

    const [results, setResults] = useState([]);

    var callback = (data, filter) =>{
        var results = data.results;
        if(filter){
            results = filterResults(results, query);
        }
        updateResults(setResults, results);
    }

    useEffect(()=>{
        var filter = true;
        if(query === undefined){
            filter = false;
        }

        let uri = api_base_url+'/api/sessao/sessaoplenaria/';

        fetchContent(uri, null, 'GET', (data)=>{
            callback(data, filter);
        });
    }, [query]);

    var inputs = {
        1:{
            placeholder:"Buscar sessões", type:"text", name:"search"
        },
    };

    var loggedIn = JSON.parse(localStorage.getItem("isLoggedIn"));

    if(!loggedIn){
        return <Navigate to="/login"/>;
    }

    return (
        <div>
            <UpperMenu/>
            <SearchPage form={<Form className={"search-page-form"} formFunction={search} inputs={inputs}/>} title={"Sessões"} results={results} handleSearchSubmit={search}/>
        </div>
    )
};

export default SessionSearch;