import { Link } from "react-router-dom";
import { api_base_url } from "../App";

const ProfileSeachItem = (props) => {

    var img = props.profile.fotografia === null ? null : api_base_url+props.profile.fotografia_cropped;

    return (
        <div className={props.className}>
            <div className="px-4 pb-4">
                <div className="p-4 event-card-container relative">
                    <div>
                        <div className={"d-flex w-100" + (img? " pb-3" : "")}>
                            <div className="d-flex profile-pic">
                                <img className="w-100 rounded-border" src={img}></img>
                            </div>
                        </div>
                        <div className="flex">
                            <Link to={"/profile/"+props.profile.id} className="link">
                                <div className="bold p-0">
                                    {props.profile.nome_parlamentar}
                                </div>
                            </Link>
                        </div>
                        <div className="profile-properties">
                            <div className="p-0">
                                <div>
                                    {props.profile.partido}
                                </div>
                            </div>
                            <div className="p-0">
                                <div>
                                    {"Parlamentar "+(props.profile.ativo ? "ativo" : "inativo")}
                                </div>
                            </div>
                            <div className="p-0">
                                <div>
                                    {(props.profile.titular.toLowerCase() === "sim" ? "Parlamentar titular" : "")
                                    +(props.profile.titular.toLowerCase() === "não" ? "Parlamentar não titular" : "")}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="absolute right-0">
                        <div className="options flex">
                            <div className="w-100"></div>
                            <div className="p-4">
                                <input className="form-btn m-0" onClick={() => props.follow(props.user_id, props.councilor_id, props.follow_bool)} type="submit" value={props.follow_value}/>
                            </div>
                        </div>
                    </div>
                    {/*
                    <form>
                        <div className="w-100 button-container button-font">
                            <input type="submit" className="form-btn" value="Seguir"/>
                        </div>
                    </form>
                    <Link to={"/proposition_search/"+props.user}>
                        <div className="w-100 button-container button-font">
                            <input type="submit" className="form-btn" value="Proposições"/>
                        </div>
                    </Link>*/}
                </div>
            </div>
        </div>
    )
};

export default ProfileSeachItem;