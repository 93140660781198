import React from "react";
import DefaultProfilePicture from "../images/default-profile-picture.png"
import EditIcon from "../images/pencil.png"
import NotifyIcon from "../images/notification.png"
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import dbQuery from "../gets/DBQuery.js";
import { api_base_url } from "../App.js";
import fetchContent from "../gets/Fetch.js";
import { db_base_url } from "../App.js";
import Proposition from "../components/Proposition.js";

const follow = (user_id, councilor_id, follow_bool) =>{
    dbQuery("follow_councilor", {
        follow: follow_bool,
        time_followed: new Date().toISOString(),
        councilor_id: councilor_id
    }, (data)=>{
        if(follow_bool){
            alert("Parlamentar seguido com sucesso.");
        }
        else{
            alert("Parlamentar deixado de seguir com sucesso.");
        }
        //reload page
        window.location.reload();
    });
};

const updateResults = (setResults, newPropositions, followed_propositions) =>{
    var user_id = JSON.parse(localStorage.getItem("user_id"));

    var propositions = newPropositions;
    var results = [];
    for (var i = 0; i < propositions.length; i++) {
        var proposition_id = propositions[i].numero_proposicao;

        var follow_bool = false;

        for (var j = 0; j < followed_propositions.length; j++) {
            if(proposition_id === Number(followed_propositions[j].proposition_id)){
                follow_bool = followed_propositions[j].follow === "1";
                break;
            }
        }

        var follow_value = follow_bool ? "Seguindo" : "Seguir";

        results.push(<Proposition className={"default-border-top"} proposition={propositions[i]} user_id={user_id} proposition_id={proposition_id} follow_bool={follow_bool} follow={
            (user_id, proposition_id, follow_bool)=>{follow(user_id, proposition_id, !follow_bool)}
        } follow_value={follow_value}/>);
    }
    if(propositions.length === 0){
        results.push(
            <div className="pb-2">
                <div className="event-card-container w-100">
                    Esse parlamentar não possui proposições.
                </div>
            </div>
        );
    }
    else{
        results.push(
            <div className="pb-2">
                <div className="event-card-container w-100">
                    Fim das proposições.
                </div>
            </div>
        );
    }
    setResults(results);
}

const filterResults = (propositions, councilor_id) =>{
    var newPropositions = [];
    for (var i = 0; i < propositions.length; i++) {
        if(propositions[i].autor == councilor_id){
            newPropositions.push(propositions[i]);
        }
    }
    return newPropositions;
};

const Profile = (props) => {

    const [profileData, setProfileData] = useState({});
    const [profileIsCouncilor, setIsCouncilor] = useState(false);
    const [profilePictureURL, setProfilePictureURL] = useState(undefined);
    const [isFollowing, setIsFollowing] = useState(false);

    var profileOwner = props.profileOwner;
    const isOwner = profileOwner === undefined;
    if(isOwner){
        profileOwner = JSON.parse(localStorage.getItem("user_id"));
    }

    var user_id = JSON.parse(localStorage.getItem("user_id"));
    var username = profileData.username;
    var bio = profileData.bio;
    var email = profileData.email;
    var isEditable = props.isEditable? props.isEditable : false;

    if(!isOwner){
        isEditable = false;
    }

    const [results, setResults] = useState([]);

    const setPropositionRemoteValues = (data, filter = true, username) =>{
        let uri = api_base_url+'/api/materia/proposicao/';
        fetchContent(uri, null, 'GET', (data)=>{
            //get all the propositions that are followed by the user, so the button displays the correct text

            dbQuery("get_propositions_followed_by_user_id", {}, (followed_propositions)=>{
                var propositions = data.results;
                if(filter){
                    propositions = filterResults(propositions, profileOwner);
                }

                //set the the propositions autor to be the councilor's username

                for (var i = 0; i < data.results.length; i++) {
                    var proposition = data.results[i];
                    proposition.nome_autor = username;
                }

                updateResults(setResults, propositions, followed_propositions);
            });
        });
    }

    const getProfilePictureDB = (user_id, found_callback, not_found_callback, default_callback = ()=>{}) =>{
        dbQuery("get_profile_picture_by_user_id", {
            id: user_id
        }, (data)=>{
            if(data.length > 0){
                var new_profile_pic = db_base_url + data[0].profile_pic;
                setProfilePictureURL(new_profile_pic);
                found_callback();
            }
            else{
                not_found_callback();
            }
            default_callback();
        });
    }

    const getProfilePictureAPI = (councilor_id, found_callback, not_found_callback, default_callback = ()=>{}) =>{
        let uri = api_base_url+'/api/parlamentares/parlamentar/search_parlamentares/';

        fetchContent(uri, null, 'GET', (data)=>{
            var profiles = data;
            var profiles = profiles.filter((profile)=>{
                return profile.id == councilor_id;
            });
            if(profiles.length > 0){
                var profile = profiles[0];
                if(profile.fotografia_cropped !== ""){
                    setProfilePictureURL(api_base_url+profile.fotografia_cropped);
                    found_callback();
                }
                else{
                    not_found_callback();
                }
            }
            else{
                not_found_callback();
            }
            default_callback();
        });
    }

    useEffect(()=>{
        setProfilePictureURL(undefined);
        setProfileData({});
        setIsCouncilor(false);
        setIsFollowing(false);

        if(isOwner){
            dbQuery("get_user_by_id", {
                id: profileOwner
            }, (data)=>{
                if(data.length > 0){
                    data = data[0];
                    setProfileData(data);
                }
            });
            getProfilePictureDB(profileOwner, ()=>{}, ()=>{
                dbQuery("get_councilor_by_user_id", {
                    id: profileOwner
                }, (data)=>{
                    if(data.length > 0){
                        var councilor_id = data[0].id_ex_parlamentary;
                        getProfilePictureAPI(councilor_id, ()=>{}, ()=>{});
                    }
                });
            });
            dbQuery("get_councilor_by_user_id", {
                id: profileOwner
            }, (data)=>{
                if(data.length > 0){
                    setIsCouncilor(true);
                }
            });
        }else{
            dbQuery("get_user_by_councilor_id", {
                id: profileOwner
            }, (data)=>{
                if(data.length > 0){
                    data = data[0];
                    setProfileData(data);
                    setIsCouncilor(true);
                    setPropositionRemoteValues(data, true, data.username);

                    //get profile picture

                    getProfilePictureDB(user_id, ()=>{}, ()=>{
                        getProfilePictureAPI(data.id_ex_parlamentary, ()=>{}, ()=>{}, ()=>{});
                    }, ()=>{}); //if not found in db, try to get from api
                }
                else{
                    //get profile picture

                    let uri = api_base_url+'/api/parlamentares/parlamentar/search_parlamentares/';

                    fetchContent(uri, null, 'GET', (data)=>{
                        var profiles = data;
                        var profiles = profiles.filter((profile)=>{
                            return profile.id == profileOwner;
                        });
                        if(profiles.length > 0){
                            var profile = profiles[0];
                            profile.username = profile.nome_parlamentar;
                            var titular = "";
                            if(profile.titular.toLowerCase() === "sim"){
                                titular = "Parlamentar titular";
                            }else if(profile.titular.toLowerCase() === "não"){
                                titular = "Parlamentar não titular";
                            }
                            titular = titular ? " - " + titular : "";
                            profile.bio = profile.partido + titular;
                            setProfileData(profile);
                            setIsCouncilor(true);
                            setPropositionRemoteValues(data, true, profile.username);
                            if(profile.fotografia_cropped !== ""){
                                setProfilePictureURL(api_base_url+profile.fotografia_cropped);
                            }
                        }
                    });
                }
            });
            dbQuery("get_follow_councilor_by_user_id_and_councilor_id", {
                councilor_id: profileOwner
            }, (data)=>{
                if(data.length > 0){
                    setIsFollowing(data[0].follow === "1");
                }
            });
        }
    }, [profileOwner]);

    const handleModifyProfile = (event) => {
        event.preventDefault();

        var form = document.getElementsByClassName("modify-profile-form")[0];

        var user = profileData;

        for(var i = 0; i < form.length; i++){
            var input = form[i];
            if(input.value && input.value !== ""){
                if(input.name === "username"){
                    user.username = input.value;
                }
                else if(input.name === "bio"){
                    user.bio = input.value;
                }
            }
        }

        dbQuery("modify_profile_by_id", {
            username: user.username,
            bio: user.bio
        }, (data)=>{
            alert("Perfil atualizado com sucesso!");
            window.location.reload();
        });
    }

    const handleProfilePictureUpload = (event) => {
        event.preventDefault();

        var { media } = document.getElementsByClassName("profile-picture-form")[0];

        var image = media.files[0];

        var base64 = "";

        var image_name = image.name;

        if(image){
            var reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = function () {
                base64 = reader.result;

                dbQuery("profile_pic_upload", {
                    profile_pic: {name: image.name, type: image.type, base64: base64}
                }, (data)=>{
                    alert("Foto de perfil atualizada com sucesso!");
                    window.location.reload();
                });
            };
        }
    }

    return (
        <div className="default-page-content-size">
            <div className="profile-header">
                <div className="navigation">
                    <div className="profile centered-flex-container pb-3">
                        <div className="profile-identity d-flex-column">
                            <div className="relative">
                                <div className="profile-picture-container">
                                    <img className="profile-picture" src={profilePictureURL ? profilePictureURL : DefaultProfilePicture} alt="Profile picture"/>
                                </div>
                                {isEditable &&
                                <div className="absolute w-100 h-100">
                                    <form className="profile-picture-form w-100 h-100" onSubmit={handleProfilePictureUpload}>
                                        <div className="v-hidden">
                                            <input type="file" accept="image/*" name="media" id="media" onChange={handleProfilePictureUpload}/>
                                        </div>
                                        <div className="w-100 h-100 link rounded-border" onClick={()=>{
                                            var media = document.getElementById("media");
                                            media.click();
                                        }}></div>
                                    </form>
                                </div>}
                            </div>
                        </div>
                        <div className="padding w-100"></div>
                        {!isEditable &&
                            <div className="profile-options d-flex">
                                    {isOwner &&
                                    <Link to="/modify_profile">
                                        <div className="icon-button d-flex">
                                            <img className="icon" src={EditIcon} alt="Editar"/>
                                        </div>
                                    </Link>
                                    }
                                    {!isOwner &&
                                    <form className="profile-follow-form">
                                        <div onClick={()=>{follow(user_id, profileOwner, !isFollowing)}} className="icon-button d-flex link">
                                            <img className="icon" src={NotifyIcon} alt="Notificar"/>
                                        </div>
                                    </form>}
                            </div>
                        }
                    </div>
                    <form className="modify-profile-form" onSubmit={handleModifyProfile}>
                        <div className="profile-name bold pb-1">
                            {!isEditable?
                            username
                            :
                            <input name="username" type="text" placeholder={username} className="w-100"/>
                            }
                        </div>
                        <div className="profile-description pb-1">
                            {!isEditable?
                            bio
                            :
                            <textarea name="bio" placeholder={bio} className="w-100"></textarea>
                            }
                        </div>
                        <div>
                            {!isEditable &&
                                <div>
                                    {profileIsCouncilor &&
                                    <a href={"mailto:"+email} className="link">{"Enviar mensagem"}</a>}
                                </div>
                            }
                        </div>
                        {isEditable &&
                        <div className="d-flex pt-2">
                            <div className="w-100"></div>
                            <input type="submit" class="small-form-btn" value="Confirmar mudanças"/>
                        </div>
                        }
                    </form>
                </div>
            </div>
            <div className="px-4">
                {profileIsCouncilor && results}
            </div>
        </div>
    )
};

export default Profile;