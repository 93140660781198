import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Home from "./routes/Home.js";
import Login from "./routes/Login.js";
import ModifyProfile from "./routes/ModifyProfile.js";
import Registration from "./routes/Registration.js";
import AccountSearch from "./routes/AccountSearch.js";
import ProfilePage from "./routes/ProfilePage.js";
import PropositionSearch from "./routes/PropositionSearch.js";
import OrderSearch from "./routes/OrderSearch.js";
import SessionSearch from "./routes/SessionSearch.js";
import Contact from "./routes/Contacts.js";
import Notifications from "./routes/Notifications.js";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home/>
  },
  {
    path: "/home",
    element: <Home/>
  },
  {
    path: "/login",
    element: <Login/>
  },
  {
    path: "/modify_profile",
    element: <ModifyProfile/>
  },
  {
    path: "/registration",
    element: <Registration/>
  },
  {
    path: "/councilors/:query",
    element: <AccountSearch/>
  },
  {
    path: "/councilors",
    element: <AccountSearch/>
  },
  {
    path: "/proposition_search/:query",
    element: <PropositionSearch/>
  },
  {
    path: "/proposition_search",
    element: <PropositionSearch/>
  },
  {
    path: "/profile/:user",
    element: <ProfilePage/>
  },
  {
    path: "/profile",
    element: <ProfilePage/>
  },
  {
    path: "/orders/:query",
    element: <OrderSearch/>
  },
  {
    path: "/orders",
    element: <OrderSearch/>
  },
  {
    path: "/sessions/:query",
    element: <SessionSearch/>
  },
  {
    path: "/sessions",
    element: <SessionSearch/>
  },
  {
    path: "/notifications",
    element: <Notifications/>
  },
  {
    path: "/contact",
    element: <Contact/>
  },
  // {
  //   path: "*",
  //   element: <NotFound/>
  // },
])

const Router = (props) => {

    return(
        <RouterProvider router={router} />
        /*<React.StrictMode>
            
        </React.StrictMode>*/
    )
}

export default Router;