import React from "react";

const Session = (props) => {
    return (
        <div className="pb-2">
            <div className="event-card-container w-100">
                <div className="event-card-title">{props.session.__str__}</div>
                <div className="event-card-content">Início: {props.session.hora_inicio} {props.session.data_inicio}</div>
                <div className="event-card-content">Fim: {props.session.hora_fim} {props.session.data_fim}</div>
                <div className="event-card-content">{props.session.finalizada? "Finalizada" : "Em andamento"}</div>
            </div>
        </div>
    )
}

export default Session;