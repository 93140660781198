const PropositionForm = {
    1:{
        placeholder:"Termos da pesquisa", type:"text", name:"search"
    },
    2:{
        placeholder:"Proponente", type:"text", name:"proponent"
    },
    3:{
        placeholder:"Número da proposição", type:"text", name:"number"
    },
    4:{
        placeholder:"Ano da proposição", type:"text", name:"year"
    },
    5:{
        btn:true, type:"submit", value:"Pesquisar", required:true
    },
}

export default PropositionForm;