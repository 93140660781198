const Contact = (contact) => {

    //remove parenthesis and spaces from phone number to put it in the href
    var phone = contact.phone ? contact.phone : "";

    phone = phone.replace("(", "");
    phone = phone.replace(")", "");
    phone = phone.replace(" ", "");

    //if number has 10 digits, add a 9 between the DDD and the number
    if (phone.length === 10) {
        phone = phone.slice(0, 2) + "9" + phone.slice(2);
    }

    return (
        <div>
            {contact.name &&
            <div className="">
                {contact.name}
            </div>}
            {contact.email &&
            <div className="email">
                Email: <a href={"mailto:" + contact.email} target="_blank" className="email link">{contact.email}</a>
            </div>}
            {contact.phone &&
            <div className="phone">
                Telefone: <a href={`tel:${phone}`} className="phone link">{contact.phone}</a>
            </div>}
        </div>
    )
}

export default Contact;