const PushNotification = (notificationTitle, notificationOptions) => {

    const pushNotification = (notificationTitle, notificationOptions) => {
        var notification = new Notification(notificationTitle, notificationOptions);
        console.log("notification");
        notification.onclick = function(event) {
            event.preventDefault(); // prevent the browser from focusing the Notification's tab
            window.open("/notifications", '_blank');
            notification.close();
        }
    }

    if (!("Notification" in window)) {
      alert("Esse navegador não suporta notificações de Desktop.");
    }
    else if (Notification.permission === "granted") {
        pushNotification(notificationTitle, notificationOptions);
    }
    else if (Notification.permission !== "denied") {
        Notification.requestPermission().then(function (permission) {
            if (permission === "granted") {
                pushNotification(notificationTitle, notificationOptions);
            }
        });
    }
}

export default PushNotification;