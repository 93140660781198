import React, { useEffect, useState } from "react";

import SearchPage from "../components/SeachPage.js";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import fetchContent from "../gets/Fetch.js";
import { api_base_url } from "../App.js";
import ProfileSearchItem from "../components/ProfileSearchItem.js";
import UpperMenu from "../components/UpperMenu.js";
import Form from "../components/Form.js";
import dbQuery from "../gets/DBQuery.js";

const follow = (user_id, councilor_id, follow_bool) =>{
    dbQuery("follow_councilor", {
        follow: follow_bool,
        time_followed: new Date().toISOString(),
        councilor_id: councilor_id
    }, (data)=>{
        if(follow_bool){
            alert("Parlamentar seguido com sucesso.");
        }
        else{
            alert("Parlamentar deixado de seguir com sucesso.");
        }
        //reload page
        window.location.reload();
    });
};

const updateResults = (setResults, newProfiles, followed_councilors) =>{
    var profiles = newProfiles;
    var results = [];
    for (var i = 0; i < profiles.length; i++) {

        var follow_bool = false;
        for (var j = 0; j < followed_councilors.length; j++) {
            if(profiles[i].id === Number(followed_councilors[j].councilor_id)){
                follow_bool = followed_councilors[j].follow === "1";
                break;
            }
        }

        var follow_value = follow_bool ? "Seguindo" : "Seguir";

        var user_id = JSON.parse(localStorage.getItem("user_id"));
        var councilor_id = profiles[i].id;

        results.push(<ProfileSearchItem profile={profiles[i]} follow_value={follow_value} user_id={user_id} councilor_id={councilor_id} follow_bool={follow_bool} follow={
            (user_id, councilor_id, follow_bool)=>{follow(user_id, councilor_id, !follow_bool)}
        }/>);
    }
    if(profiles.length === 0){
        results.push(
            <div className="px-4 pb-4 d-flex center-content">
                <div className="event-card-container">
                    Não houveram resultados para a sua pesquisa.
                </div>
            </div>
        );
    }
    else{
        results.push(
            <div className="px-4 pb-4 d-flex center-content">
                <div className="event-card-container">
                    Fim dos resultados.
                </div>
            </div>
        );
    }
    setResults(results);
}

const filterResults = (profiles, query, active) =>{
    var newProfiles = [];
    if(query !== undefined){
        for (var i = 0; i < profiles.length; i++) {
            var profile = profiles[i];
            var keys = Object.keys(profile);
            for (var j = 0; j < keys.length; j++) {
                var key = keys[j];
                try{
                    if(profile[key].toLowerCase().includes(query.toLowerCase())){
                        newProfiles.push(profiles[i]);
                        break;
                    }
                }
                catch(e){}
                try{
                    if(profile[key] !== undefined && profile[key] !== null){
                        if(Number(profile[key]) === Number(query)){
                            newProfiles.push(profiles[i]);
                            break;
                        }
                    }
                }
                catch(e){}
            }
        }
    }
    else{
        newProfiles = profiles;
    }
    if(active !== undefined){
        var activeProfiles = [];
        for (var i = 0; i < newProfiles.length; i++) {
            if(newProfiles[i].ativo == active){
                activeProfiles.push(newProfiles[i]);
            }
        }
        newProfiles = activeProfiles;
    }
    return newProfiles;
}

const AccountSearch = (props) => {

    const navigate = useNavigate();

    const search = (e) =>{
        e.preventDefault();
        var { search, active } = document.getElementsByClassName("search-page-form")[0];
        var query = '';
        if(search.value !== ''){
            query += 'search='+encodeURIComponent(search.value);
        }
        if(active.checked){
            if(query !== ''){
                query += '&';
            }
            query += 'active=true';
        }
        navigate("/councilors/"+query);
    }

    const state = { ...localStorage };

    const {query} = useParams();

    const search_query = query && query.includes("search=") ? query.split("search=")[1].split("&")[0] : undefined;
    const active_query = query && query.includes("active=") ? query.split("active=")[1].split("&")[0] : undefined;
    const active = active_query === "true";

    const [results, setResults] = useState([]);

    var callback = (data, filter) => {

        dbQuery("get_follow_councilor_by_user_id", {}, (followed_councilors)=>{
            var profiles = data;
            if(filter){
                profiles = filterResults(profiles, search_query, active);
            }
            updateResults(setResults, profiles, followed_councilors);
        });
    }

    useEffect(()=>{
        var filter = true;
        if(search_query === undefined && active_query === undefined){
            filter = false;
        }
        let uri = api_base_url+'/api/parlamentares/parlamentar/search_parlamentares/';

        fetchContent(uri, null, 'GET', (data)=>{
            callback(data, filter);
        });
    }, [query]);

    var inputs = {
        1:{
            placeholder:"Buscar parlamentar", type:"text", name:"search"
        },
        2:{
            label:"Ativo", type:"checkbox", name:"active"
        },
        3:{
            btn:true, type:"submit", value:"Buscar"
        }
    };

    var loggedIn = JSON.parse(localStorage.getItem("isLoggedIn"));

    if(!loggedIn){
        return <Navigate to="/login"/>;
    }

    return (
        <div>
            <UpperMenu/>
            <SearchPage form={<Form className={"search-page-form"} formFunction={search} inputs={inputs}/>} title={"Parlamentares"} results={results} handleSearchSubmit={search}/>
        </div>
    )
};

export default AccountSearch;